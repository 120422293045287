<script>
import Layout from "@/views/layouts/static/main";
// import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import AlertWidget from "@/components/general/AlertWidget";
import imageUrl from "@/assets/images/dashboard/default-logo.png";
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    // PageHeader,
    AlertWidget,
  },
  data() {
    return {
      current_domain: localStorage.getItem('url'),
      title: "Dashboard",
      items: [
        {
          text: "Shift Fish",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      loading: false,
      statCounts: {},

        approved_shifts_count: null,
        dropped_shifts_count: null,
        rejected_shifts_count: null,
        request_for_dropped_shifts_count: null,
        request_for_pickup_shifts_count: null,
        total_shifts: null,
        completed_shifts_count: null,

        approved_shifts: [],
        dropped_shifts: [],
        rejected_shifts: [],
        request_for_dropped_shifts: [],
        request_for_pickup_shifts: [],

      table_data: {
        new_shifts_size: 3,
        bonus_shifts_size: 3,
        inprogress_shifts_size: 3,
        pending_shifts_size: 3,
        releaseRequest_shifts_size: 3,
        draw: 0,
        length: 3,
      },
    };
  },

  created() {
    this.user = this.$store.state.auth.currentUser;
    this.imageUrl = imageUrl
  },
  mounted() {
    // this.fetchLocations();
    // this.locations = this.$store.state.auth.currentUser.employee.locations;
    // this.location_id = this.locations[0].id;
    this.statsCount();
  },


  methods: {
    // fetchLocations() {
    //   this.$axios.get('employee/team-member/'+this.user.id).then((response) => {
    //     let res_data = response.data.payload;
    //     this.statsCount();
    //   })
    // },

    statsCount() {
      this.loading = true;
      this.$axios.get('/dashboard/stats-counts').then(response => {
        let data = response.data.payload;
        
        this.approved_shifts_count = data.approved_shifts_count;
        this.dropped_shifts_count = data.dropped_shifts_count;
        this.rejected_shifts_count = data.rejected_shifts_count;
        this.request_for_dropped_shifts_count = data.request_for_dropped_shifts_count;
        this.request_for_pickup_shifts_count = data.request_for_pickup_shifts_count;
        this.total_shifts = data.total_shifts;
        this.completed_shifts_count = data.completed_shifts_count;


        this.approved_shifts = data.approved_shifts.data;
        this.dropped_shifts = data.dropped_shifts.data;
        this.rejected_shifts = data.rejected_shifts.data;
        this.request_for_dropped_shifts = data.request_for_dropped_shifts.data;
        this.request_for_pickup_shifts = data.request_for_pickup_shifts.data;
        
        this.loading = false;
      })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.loading = false));
    },

    rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.toString();

      return string;
    },
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" class="poppins fw-normal" /> -->
    <div class="d-flex mb-3 justify-content-between align-items-center">
        <h2 class="mb-0 f-20 fw-semibold poppins">{{ title }}</h2>
        <router-link to="/create-shift" class=""><button type="button" class="btn border-0 px-3 fw-bold primary-button text-white">Create Shift </button></router-link>
    </div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="d-grid gap-2 grid_inline">
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/upcomming_week.png">
                  <h2 class="text-dark f-30 pt-1">{{ total_shifts }}</h2>
                  <p class="text-dark mb-2">Total Shifts</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Completed' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/upcomming_week.png">
                  <h2 class="text-dark f-30 pt-1">{{ completed_shifts_count }}</h2>
                  <p class="text-dark mb-2">Completed Shifts</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Pending' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/open_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ request_for_pickup_shifts_count }}</h2>
                  <p class="text-dark mb-2">Requests for Pickup</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Released' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/dropped_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ dropped_shifts_count }}</h2>
                  <p class="text-dark mb-2">Dropped Shifts</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'ApplyForLeave' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/dropped_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ request_for_dropped_shifts_count }}</h2>
                  <p class="text-dark mb-2">Request for Dropped Shifts</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>
            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Rejected' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/dropped_shifts.png">
                  <h2 class="text-dark f-30 pt-1">{{ rejected_shifts_count }}</h2>
                  <p class="text-dark mb-2">Rejected Shifts</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>

            <div class="card rounded box-shadow px-3 py-3">
              <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Accepted' }">
                <div class="innerCard">
                  <img src="@/assets/images/dashboard/wait_for_approval.png">
                  <h2 class="text-dark f-30 pt-1">{{ approved_shifts_count }}</h2>
                  <p class="text-dark mb-2">Approved Shifts</p>
                  <!-- <span class="secondaryColor">This Week</span> -->
                </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex">
                <h4 class="card-title">Shift Pickup Requests</h4>
                <i class="blink fa fa-exclamation-circle secondaryColor mx-2 f-18" v-if="request_for_pickup_shifts.length > 0"></i>
              </div>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shiftTimeline, index ) in request_for_pickup_shifts" :key="index">
                      <td>{{ getSafe(() => shiftTimeline.shift.name) }}</td>
                      <td>
                        <p>{{ getSafe(() => shiftTimeline.employee.user.full_name) }}</p>
                      </td>
                      <td>{{ getSafe(() => shiftTimeline.shift.interval) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift.location.name) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift_role.role.name) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shiftTimeline.from) }} - {{
                        DateWithMonthWithoutYear(shiftTimeline.to) }}</td>
                    </tr>
                    <tr v-if="loading">
                    <td colspan="13">
                      <APILoader
                        :loading="loading"
                        class="align-middle"
                      ></APILoader>
                    </td>
                  </tr>
                    <tr v-if="request_for_pickup_shifts.length == 0 && loading === false">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
               </div>
              <div class="seeMore pt-3 text-center" v-if="request_for_pickup_shifts.length > 0">
                <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Pending' }">
                  View All</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex">
                <h4 class="card-title">Shift Release Requests</h4>
                <i class="blink fa fa-exclamation-circle secondaryColor mx-2 f-18"
                  v-if="request_for_dropped_shifts.length > 0"></i>
              </div>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shiftTimeline, index ) in request_for_dropped_shifts" :key="index">
                      <td>{{ getSafe(() => shiftTimeline.shift.name) }}</td>
                      <td>
                        <p>{{ getSafe(() => shiftTimeline.employee.user.full_name) }}</p>
                      </td>
                      <td>{{ getSafe(() => shiftTimeline.shift.interval) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift.location.name) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift_role.role.name) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shiftTimeline.from) }} - {{
                        DateWithMonthWithoutYear(shiftTimeline.to) }}</td>
                    </tr>
                    <tr v-if="loading">
                    <td colspan="13">
                      <APILoader
                        :loading="loading"
                        class="align-middle"
                      ></APILoader>
                    </td>
                  </tr>
                    <tr v-if="request_for_dropped_shifts.length == 0 && loading === false">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="seeMore pt-3 text-center" v-if="request_for_dropped_shifts.length > 0">
                <router-link :to="{ path: '/business-owner-shift-listing', hash: 'ApplyForLeave' }">
                  View All</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex">
                <h4 class="card-title">Approved Shifts</h4>
              </div>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shiftTimeline, index ) in approved_shifts" :key="index">
                      <td>{{ getSafe(() => shiftTimeline.shift.name) }}</td>
                      <td>
                        <p>{{ getSafe(() => shiftTimeline.employee.user.full_name) }}</p>
                      </td>
                      <td>{{ getSafe(() => shiftTimeline.shift.interval) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift.location.name) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift_role.role.name) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shiftTimeline.from) }} - {{
                        DateWithMonthWithoutYear(shiftTimeline.to) }}</td>
                    </tr>
                     <tr v-if="loading">
                    <td colspan="13">
                      <APILoader
                        :loading="loading"
                        class="align-middle"
                      ></APILoader>
                    </td>
                  </tr>
                    <tr v-if="approved_shifts.length == 0 && loading === false">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="seeMore pt-3 text-center" v-if="approved_shifts.length > 0">
                <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Accepted' }">
                  View All</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex">
                <h4 class="card-title">Dropped Shifts</h4>
              </div>
              <div class="table-responsive table-height">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee</th>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Position</th>
                      <th>Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(shiftTimeline, index ) in dropped_shifts" :key="index">
                      <td>{{ getSafe(() => shiftTimeline.shift.name) }}</td>
                      <td>
                        <p>{{ getSafe(() => shiftTimeline.employee.user.full_name) }}</p>
                      </td>
                      <td>{{ getSafe(() => shiftTimeline.shift.interval) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift.location.name) }}</td>
                      <td>{{ getSafe(() => shiftTimeline.shift_role.role.name) }}</td>
                      <td>{{ DateWithMonthWithoutYear(shiftTimeline.from) }} - {{
                        DateWithMonthWithoutYear(shiftTimeline.to) }}</td>
                    </tr>
                     <tr v-if="loading">
                    <td colspan="13">
                      <APILoader
                        :loading="loading"
                        class="align-middle"
                      ></APILoader>
                    </td>
                  </tr>
                    <tr v-if="dropped_shifts.length == 0 && loading === false">
                      <td colspan="6">
                        <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="seeMore pt-3 text-center" v-if="dropped_shifts.length > 0">
                <router-link :to="{ path: '/business-owner-shift-listing', hash: 'Released' }">
                  View All</router-link>
              </div>
            </div>
          </div>
        </div>

        
        
      </div>
    </div>
    <div>
    </div>
  </Layout>
</template>


<style scoped>
.widthImg {
  height: 40px;
  object-fit: contain;
}

.table-height {
  height: 180px;
  overflow-y: auto;
}

.no_location {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}</style>